import React from "react";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import Layout from "../components/layout";
import HelpTiles from "../components/HelpTiles";

export default function HelpIndex({ entry }) {
	const {
		heroTitle,
		heroText,
		indexPageBlocks,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = entry ?? [];

	let items = indexPageBlocks?.map((block) => {
		return {
			title: block.indexPageBlockTitle,
			text: block.indexPageBlockText,
			cta: block.indexPageBlockButton,
		};
	});

	return (
		<Layout className="layout--helpIndex">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGeneric
				title={heroTitle}
				text={heroText}
				colorTheme="dark"
				nextTheme="light"
			/>

			<HelpTiles items={items} />
		</Layout>
	);
}
